.csslider {
    text-align: left;
    position: relative;
    margin-bottom: 0px;
}

.csslider>input {
    display: none;
}

.csslider>input:nth-of-type(10):checked~ul li:first-of-type {
    margin-left: -900%;
}

.csslider>input:nth-of-type(9):checked~ul li:first-of-type {
    margin-left: -800%;
}

.csslider>input:nth-of-type(8):checked~ul li:first-of-type {
    margin-left: -700%;
}

.csslider>input:nth-of-type(7):checked~ul li:first-of-type {
    margin-left: -600%;
}

.csslider>input:nth-of-type(6):checked~ul li:first-of-type {
    margin-left: -500%;
}

.csslider>input:nth-of-type(5):checked~ul li:first-of-type {
    margin-left: -400%;
}

.csslider>input:nth-of-type(4):checked~ul li:first-of-type {
    margin-left: -300%;
}

.csslider>input:nth-of-type(3):checked~ul li:first-of-type {
    margin-left: -200%;
}

.csslider>input:nth-of-type(2):checked~ul li:first-of-type {
    margin-left: -100%;
}

.csslider>input:nth-of-type(1):checked~ul li:first-of-type {
    margin-left: 0%;
}

.csslider>ul {
    position: relative;
    /* height: 760px; */
    z-index: 1;
    font-size: 0;
    line-height: 0;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.csslider>ul>li {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 15px;
    font-size: initial;
    line-height: normal;
    -moz-transition: all 0.5s cubic-bezier(0.4, 1.3, 0.65, 1);
    -o-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s cubic-bezier(0.4, 1.3, 0.65, 1);
    transition: all 0.5s cubic-bezier(0.4, 1.3, 0.65, 1);
    vertical-align: top;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: normal;
}

.csslider>ul>li.scrollable {
    overflow-y: scroll;
}

.csslider>.navigation {
    position: absolute;
    bottom: -22px;
    left: 49%;
    z-index: 10;
    margin-bottom: -10px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.csslider>.navigation label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    background: #000;
    opacity: 0.7;
}

.csslider>.navigation label:hover:after {
    opacity: 1;
}

.csslider>.navigation label:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -7px;
    margin-top: -7px;
    background: #30c39e;
    border-radius: 50%;
    padding: 7px;
    opacity: 0;
}

.csslider>.arrows {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.csslider.inside .navigation {
    bottom: 10px;
    margin-bottom: 10px;
}

.csslider.inside .navigation label {
    border: 1px solid #7e7e7e;
}

.csslider>input:nth-of-type(1):checked~.navigation label:nth-of-type(1):after,
.csslider>input:nth-of-type(2):checked~.navigation label:nth-of-type(2):after,
.csslider>input:nth-of-type(3):checked~.navigation label:nth-of-type(3):after,
.csslider>input:nth-of-type(4):checked~.navigation label:nth-of-type(4):after,
.csslider>input:nth-of-type(5):checked~.navigation label:nth-of-type(5):after,
.csslider>input:nth-of-type(6):checked~.navigation label:nth-of-type(6):after,
.csslider>input:nth-of-type(7):checked~.navigation label:nth-of-type(7):after,
.csslider>input:nth-of-type(8):checked~.navigation label:nth-of-type(8):after,
.csslider>input:nth-of-type(9):checked~.navigation label:nth-of-type(9):after,
.csslider>input:nth-of-type(10):checked~.navigation label:nth-of-type(10):after,
.csslider>input:nth-of-type(11):checked~.navigation label:nth-of-type(11):after {
    opacity: 1;
}

.csslider>.arrows {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 26px;
    z-index: 1;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.csslider>.arrows label {
    display: none;
    position: absolute;
    top: -50%;
    padding: 8px;
    box-shadow: inset 2px -2px 0 1px #ffffff;
    cursor: pointer;
    -moz-transition: box-shadow 0.15s, margin 0.15s;
    -o-transition: box-shadow 0.15s, margin 0.15s;
    -webkit-transition: box-shadow 0.15s, margin 0.15s;
    transition: box-shadow 0.15s, margin 0.15s;
}

.csslider>.arrows label:hover {
    box-shadow: inset 3px -3px 0 2px #ff3c41;
    margin: 0 0px;
}

.csslider>.arrows label:before {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    height: 300%;
    width: 300%;
}

.csslider.infinity>input:first-of-type:checked~.arrows label.goto-last,
.csslider>input:nth-of-type(1):checked~.arrows>label:nth-of-type(0),
.csslider>input:nth-of-type(2):checked~.arrows>label:nth-of-type(1),
.csslider>input:nth-of-type(3):checked~.arrows>label:nth-of-type(2),
.csslider>input:nth-of-type(4):checked~.arrows>label:nth-of-type(3),
.csslider>input:nth-of-type(5):checked~.arrows>label:nth-of-type(4),
.csslider>input:nth-of-type(6):checked~.arrows>label:nth-of-type(5),
.csslider>input:nth-of-type(7):checked~.arrows>label:nth-of-type(6),
.csslider>input:nth-of-type(8):checked~.arrows>label:nth-of-type(7),
.csslider>input:nth-of-type(9):checked~.arrows>label:nth-of-type(8),
.csslider>input:nth-of-type(10):checked~.arrows>label:nth-of-type(9),
.csslider>input:nth-of-type(11):checked~.arrows>label:nth-of-type(10) {
    display: block;
    left: 5%;
    right: auto;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.csslider.infinity>input:last-of-type:checked~.arrows label.goto-first,
.csslider>input:nth-of-type(1):checked~.arrows>label:nth-of-type(2),
.csslider>input:nth-of-type(2):checked~.arrows>label:nth-of-type(3),
.csslider>input:nth-of-type(3):checked~.arrows>label:nth-of-type(4),
.csslider>input:nth-of-type(4):checked~.arrows>label:nth-of-type(5),
.csslider>input:nth-of-type(5):checked~.arrows>label:nth-of-type(6),
.csslider>input:nth-of-type(6):checked~.arrows>label:nth-of-type(7),
.csslider>input:nth-of-type(7):checked~.arrows>label:nth-of-type(8),
.csslider>input:nth-of-type(8):checked~.arrows>label:nth-of-type(9),
.csslider>input:nth-of-type(9):checked~.arrows>label:nth-of-type(10),
.csslider>input:nth-of-type(10):checked~.arrows>label:nth-of-type(11),
.csslider>input:nth-of-type(11):checked~.arrows>label:nth-of-type(12) {
    display: block;
    right: 5%;
    left: auto;
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

#slider1>input:nth-of-type(1):checked~ul #bg,
#slider1>input:nth-of-type(2):checked~ul #bg1,
#slider1>input:nth-of-type(3):checked~ul #bg2,
#slider1>input:nth-of-type(4):checked~ul #bg3 {
    width: 100%;
    -moz-transition: 0.5s 0.5s;
    -o-transition: 0.5s 0.5s;
    -webkit-transition: 0.5s 0.5s;
    transition: 0.5s 0.5s;
    text-align: center;
    padding: 0;
    height: 100%;
}

#slider1>input:nth-of-type(1):checked~ul #bg div,
#slider1>input:nth-of-type(2):checked~ul #bg1 div,
#slider1>input:nth-of-type(3):checked~ul #bg2 div,
#slider1>input:nth-of-type(4):checked~ul #bg3 div {
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    -webkit-transform: translate(0);
    transform: translate(0);
    -moz-transition: 0.5s 0.9s;
    -o-transition: 0.5s 0.9s;
    -webkit-transition: 0.5s 0.9s;
    transition: 0.5s 0.9s;
}


/* responsive */
@media(max-width: 991px) {
    .csslider>.navigation {
        left: 47%;
    }
}

@media(max-width: 480px) {
    .csslider>.navigation {
        left: 42%;
    }
}

@media(max-width: 320px) {
    .csslider>.navigation label {
        padding: 4px;
    }
}

/* //responsive */